import React from "react";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb/";
import Mosaic from "../components/Mosaic";
import { mosaics } from "../data/collections-mosaic";
import { collections } from "../data/featured-collections";
import Store from "../components/Store"
import FeatureCollectionCarousel from "../components/FeaturedCollection/FeaturedCollectionCarousel"
import { bookArticles } from "../data/books-list"

export default function Collections() {
	const path = [{ label: "Inici", to: "/" }, { label: "La Pedrera" }];

	const renderMosaics = () => {
		return (
			mosaics &&
			mosaics.map((item) => {
				return (
					<Mosaic
						imgLeft={item.imgLeft}
						imgRight={item.imgRight}
						text={item.text}
						isMosaic={item.isMosaic}
					/>
				);
			})
		);
	};

	return (
		<Layout>
			<div className="Collections">
				<div className="Collections-container">
					<Breadcrumb path={path} />
					<h2 className="Collections-title">La Pedrera</h2>
					<p className="Collections-text">
						Descobreix les col·leccions inspirades en l'arquitectura
						de La Pedrera
					</p>
					<section className="Collections-mosaics">
						{renderMosaics()}
					</section>
					<section className="Collections-featured">
						<p className="Collections-text featured">
							Explora altres col·leccions relacionades amb tot el
							que t'ofereix l'interior de La Pedrera
						</p>
					</section>
				</div>
				<div className="Collections-featured-carousel">
					<FeatureCollectionCarousel slides={collections} scrollInitialValue={2} />
				</div>
				<div className="Collections-container">
					<section className="Collections-featured-product">
						<Store productList={bookArticles} />
					</section>
				</div>
			</div>
		</Layout>
	);
}
