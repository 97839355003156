import Collection1 from "../images/Collection/collection-1.jpg"

export const collections = [
    {
        id:1,
        image: Collection1,
        text: "Barcelona 1900: el pis",
        link:"/",
        backgroundColor: '#C2BBA4'
    },
    {
        id:2,
        image: Collection1,
        text: "Barcelona 1900: el pis",
        link:"/",
        backgroundColor: '#8F9F8B'
    },
    {
        id:3,
        image: Collection1,
        text: "Barcelona 1900: el pis",
        link:"/",
        backgroundColor: '#4A6770'
    },
    {
        id:2,
        image: Collection1,
        text: "Barcelona 1900: el pis",
        link:"/",
        backgroundColor: '#8F9F8B'
    },
]