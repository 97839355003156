import Mosaic1 from "../images/Mosaic/mosaic-1.jpg"


export const mosaics = [
    {
        imgLeft: Mosaic1,
        imgRight: Mosaic1,
        text: "La Façana",
        link:"/",
        isMosaic: false
    },
    {
        imgLeft: Mosaic1,
        imgRight: Mosaic1,
        text: "La Façana",
        link:"/",
        isMosaic: false
    },
    {
        imgLeft: Mosaic1,
        imgRight: Mosaic1,
        text: "La Façana",
        link:"/",
        isMosaic: false
    },
    {
        imgLeft: Mosaic1,
        imgRight: Mosaic1,
        text: "La Façana",
        link:"/",
        isMosaic: false
    },
]