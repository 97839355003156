import React, { Fragment, useEffect, useState } from 'react';
import FeaturedCollection from '../FeaturedCollection/FeaturedCollectionItem';

export default function FeaturedCollectionCarousel(props) {
	const { slides, scrollInitialValue } = props;

	const itemsPerPage = 1;
	const [scrollIndex, setscrollIndex] = useState(scrollInitialValue);

	const refs = slides.map(() => {
		return React.createRef();
	});

	useEffect(() => {
        const resizeListen = () => {
            let width = window.innerWidth;
            if (width < 575) setscrollIndex(0);
            else setscrollIndex(scrollInitialValue);
        };
        resizeListen();
        window.addEventListener("resize", resizeListen);
        return () => {
            window.removeEventListener("resize", resizeListen);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


	useEffect(() => {
		let targetRef = refs[scrollIndex];
		if (
			targetRef &&
			targetRef.current &&
			targetRef.current.scrollIntoView
		) {
			targetRef.current.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "end"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollIndex]);

	const increaseIndex = () => {
		let width = window.innerWidth;
		const currentItem = scrollIndex + itemsPerPage;
		if(width < 575){
			if (slides.length === currentItem) {
				setscrollIndex(0);
				setTimeout(() => {
					setscrollIndex(0)
				}, 500);
				return;
			}
	
			setscrollIndex(currentItem);
		}else{
			if (slides.length === currentItem) {
				setscrollIndex(0);
				setTimeout(() => {
					setscrollIndex(scrollInitialValue)
				}, 500);
				return;
			}
	
			setscrollIndex(currentItem);
		}
	};

	const renderBtn = () => {
		return (
			<button
				className="Carousel-btn-right"
				onClick={increaseIndex}
			>
				<i className="i-custom-arrow" />
			</button>
		)
	}

	const renderCollections = () => {
		return slides&&slides.map((item, index)=>{
			return(
				<FeaturedCollection 
					refe={refs[index]}
					key={item.index}
					image={item.image}
					text={item.text}
					link={item.link}
					backgroundColor={item.backgroundColor}
				/>
			)
		})
	}

	return (
		<Fragment>
			{renderBtn()}
			<div className="FeaturedCollectionCarousel">
				{renderCollections()}
			</div>
		</Fragment>
	)
}
