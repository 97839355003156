import React from 'react'
import { Link } from 'gatsby'
import Proptypes from 'prop-types'

export default function Mosaic(props) {
    const {className, imgLeft, imgRight, text, link, isMosaic } = props

    const renderMosaicImage = () => {
        if(!isMosaic) return <img className="Mosaic-image" src={imgLeft} alt="Mosaic" />

        return(
            <div className="Mosaic-content">
                <img className="Mosaic-image mosaic" src={imgLeft} alt="Mosaic 1" />
                <img className="Mosaic-image mosaic" src={imgRight} alt="Mosaic 2" />
            </div>
        )
    }
    return (
        <div className={`Mosaic ${className}`}>
            <Link to={link} className="Mosaic-link">
                {renderMosaicImage()}
                <Link className="Mosaic-text" to={link}>{text}</Link>
            </Link>
        </div>
    )
}

Mosaic.defaultProps = {
    className: "",
    text: "",
    link: "/",
    imgLeft: "",
    imgRight: "",
}

Mosaic.propTypes = {
    className: Proptypes.string,
    text: Proptypes.string,
    imgLeft: Proptypes.string,
    imgRight: Proptypes.string,
    link: Proptypes.string,
}